export function getOrderStatusText(status) {
    switch (+status) {
        case 1:
            return 'Ожидает оплаты';
        case 2:
            return 'Оплачен';
        case 3:
            return 'Отменен';
        case 4:
            return 'Возврат';
        case 5:
            return 'Обрабатывается';
        case 6:
            return 'Завершён';
        case 7:
            return 'Обрабатывается менеджером';
        default:
            return '';
    }
}
