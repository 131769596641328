<template>
    <div>
        <div class="grid grid-cols-12 gap-4 mb-8">
            <div class="col-span-3">
                <input
                    :value="startDate"
                    type="date"
                    class="form-control w-full"
                    autocomplete="off"
                    @input="(e) => handleDateChange(e, 'start_date')"
                />
            </div>

            <div class="col-span-3">
                <input
                    :value="endDate"
                    type="date"
                    class="form-control w-full"
                    autocomplete="off"
                    @input="(e) => handleDateChange(e, 'end_date')"
                />
            </div>

            <div class="col-span-3 relative">
                <Multiselect
                    v-model="filters.managerId"
                    track-by="id"
                    label="first_name"
                    value-prop="id"
                    placeholder="Менеджер"
                    :hide-selected="false"
                    :close-on-select="false"
                    :searchable="true"
                    :options="mangersOptions"
                >
                    <template #option="{ option }">
                        <span>{{ option.first_name }}</span>
                        <span v-if="option.last_name">{{ `&nbsp;${option.last_name}` }}</span>
                    </template>
                </Multiselect>
            </div>

            <div class="col-span-3">
                <button type="button" class="btn btn-primary w-full" @click="handleSearchClick">Поиск</button>
            </div>
        </div>

        <ContentTable
            :table-actions="tableActions"
            :headers="tableHeaders"
            :table-rows="tableData"
            :meta="pagination"
            :loading="loading"
            @cancel="cancelAction"
        />

        <PopupCancelModal
            :is-open="modalCancelOpen"
            :entity-name="modalCancelCaption"
            :modal-data="modalCancelRow"
            @update:isOpen="modalCancelOpen = $event"
            @cancelModalAccepted="onCancelModalAccepted"
        />
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ContentTable from '@/components/tables/ContentTable';
import PopupCancelModal from '@/components/modals/PopupCancelModal.vue';
import { errorResponse } from '@/mixins/form/form-mixin';
import { getOrderStatusText } from '@/utils/orders';

const inputDateFormat = 'YYYY-MM-DD';
const filtersDateFormat = 'DD.MM.YYYY';

export default {
    components: { ContentTable, Multiselect, PopupCancelModal },
    mixins: [errorResponse],
    async beforeRouteUpdate(to, from, next) {
        await this.fetchOrders({ ...to.query });
        next();
    },
    data() {
        return {
            filters: {
                managerId: null,
                start_date: null,
                end_date: null,
            },
            loading: false,
            managersLoading: false,
            tableActions: [
                {
                    name: 'cancel-order',
                    title: 'отменить бронь',
                    class: 'bg-red-400',
                    icon: 'SlashIcon',
                    cbName: 'cancel',
                },
            ],
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'cruise', label: 'Название круиза' },
                { field: 'created_at', label: 'Дата брони' },
                { field: 'manager', label: 'Менеджер' },
                { field: 'customer', label: 'Покупатель' },
                { field: 'price', label: 'Сумма' },
                { field: 'status_name', label: 'Статус' },
                { field: 'bitrix_deal_link', label: 'Ссылка на битрикс' },
            ],
            tableData: [],
            pagination: null,
            modalCancelOpen: false,
            modalCancelCaption: null,
            modalCancelRow: null,
        };
    },
    computed: {
        startDate() {
            return dayjs(this.filters.start_date, filtersDateFormat).format(inputDateFormat);
        },
        endDate() {
            return dayjs(this.filters.end_date, filtersDateFormat).format(inputDateFormat);
        },
        preparedFilters() {
            const copy = { ...this.filters };

            for (const [k] of Object.entries(copy)) {
                if (k === 'start_date' || k === 'end_date') {
                    copy[k] = dayjs(copy[k], filtersDateFormat).format(inputDateFormat);
                }
            }

            if (this.$route?.query?.page) {
                copy.page = this.$route.query.page;
            }

            return copy;
        },
    },
    async created() {
        dayjs.extend(customParseFormat);

        this.setFilterDatesOnCreated();

        this.$store.commit('main/setPageHeader', 'Заказы');
        this.fetchManagers();
        await this.fetchOrders(this.$route.query);
    },
    methods: {
        async fetchOrders(params) {
            try {
                this.loading = true;
                const { data } = await this.axios.get('/orders', { params });
                this.tableData = data.data.map((row) => {
                    return { ...row, status_name: this.gettingOrderStatus(row.status) };
                });

                this.pagination = data.meta;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch orders: ' + ex);
            }
        },
        async fetchManagers() {
            try {
                this.managersLoading = true;
                const { data } = await this.axios.get('/managers-options'); // на managers пока получаю 403 и логаутит 😣
                this.mangersOptions = data.data;
                this.managersLoading = false;
            } catch (ex) {
                this.managersLoading = false;
                console.log('cant fetch managers: ' + ex);
            }
        },
        async handleSearchClick() {
            this.$router.push({
                name: 'orders',
                query: {
                    ...this.$route.query,
                    ...this.filters,
                },
            });
            await this.fetchOrders(this.filters);
        },
        handleDateChange(e, key) {
            const target = e.target;

            this.filters[key] = target.value ? dayjs(target.value, inputDateFormat).format(filtersDateFormat) : null;
        },
        setFilterDatesOnCreated() {
            const today = dayjs();

            const startDate = this.$route.query.start_date || `01.${today.month() + 1}.${today.year()}`;
            const endDate = this.$route.query.end_date || null;

            this.filters.managerId = this.$route.query.managerId || null;

            this.filters.start_date = dayjs(startDate, filtersDateFormat).format(filtersDateFormat);
            this.filters.end_date = endDate
                ? dayjs(endDate, filtersDateFormat).format(filtersDateFormat)
                : dayjs().format(filtersDateFormat);
        },
        gettingOrderStatus(statusNumber) {
            return getOrderStatusText(statusNumber);
        },
        cancelAction(row) {
            this.modalCancelOpen = true;
            this.modalCancelCaption = 'заказа';
            this.modalCancelRow = row;
        },
        async onCancelModalAccepted(data) {
            try {
                await this.axios.post(`/orders/${data.id}/cancel`);
                await this.fetchOrders();
                this.modalDeleteOpen = false;
            } catch (error) {
                this.modalDeleteOpen = false;
                this.$notify({ text: error?.response?.data?.message || error?.message, type: 'error' });
            }
        },
    },
};
</script>

<style></style>
