<template>
    <div v-if="isOpen" class="my-modal fixed flex justify-center items-center w-full h-full left-0 top-0">
        <transition name="fade">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body p-0">
                        <div class="p-5 text-center">
                            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
                            <div class="text-3xl mt-5">Отменить бронь {{ entityName }}?</div>
                        </div>
                        <div class="px-5 pb-8 text-center">
                            <button type="button" class="btn btn-danger w-40 mr-1" @click="modalAccepted">
                                Отменить бронь
                            </button>
                            <button
                                type="button"
                                class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300"
                                @click.prevent="closeModal"
                            >
                                Отмена
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'PopupCancelModal',
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        entityName: {
            type: String,
            default: null,
        },
        modalData: {
            type: null,
            default: null,
        },
    },
    emits: ['update:isOpen', 'cancelModalAccepted'],
    methods: {
        closeModal() {
            this.$emit('update:isOpen', false);
        },
        modalAccepted() {
            this.$emit(`cancelModalAccepted`, this.modalData);
            this.$emit('update:isOpen', false);
        },
    },
};
</script>

<style scoped lang="scss">
.my-modal {
    background: rgba(#000, 0.5);
    z-index: 10000;
}
::v-deep {
    .modal-dialog {
        width: 400px;
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
